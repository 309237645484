import React from "react"
import { Box, Flex, Grid } from "@theme-ui/components"
import RichText from "./text/rich-text"

const TextModule = ({ data, padBottom, padTop, padX }) => {
  const columnsTotal = data.columns ? data.columns.length : 0

  return (
    <Flex
      sx={{
        maxWidth: "1200px",
        justifyContent: "center",
        width: "100%",
      }}
      mx={"auto"}
      px={padX != undefined ? padX : [3, "40px"]}
      pt={padTop ? [5, 6] : [2, 3]}
      pb={padBottom ? [5, 6] : [2, 3]}
    >
      <Grid
        columns={[1, 1, columnsTotal]}
        sx={{ columnGap: ["4"], width: "100%", rowGap: ["4", "4", "inherit"] }}
      >
        {(data.columns || []).map(({ text, enlarge, center }, idx) => {
          return (
            <Box
              key={idx}
              sx={{
                width: "100%",
              }}
            >
              <RichText
                html={text.childMarkdownRemark.html}
                enlarge={enlarge}
                center={center}
              />
            </Box>
          )
        })}
      </Grid>
    </Flex>
  )
}

export default TextModule
