import React from "react"
import { Box } from "theme-ui"

const RichText = (props) => {
  const { html, sx, enlarge, center } = props

  const baseFontSize = props.baseFontSize ? props.baseFontSize : "inherit"

  return (
    <Box
      sx={{
        strong: { fontWeight: "400" },
        fontFamily: "pragmatica-extended",
        fontSize: enlarge ? "15px" : baseFontSize,
        lineHeight: enlarge ? "1.5em" : "inherit",
        textAlign: center ? "center" : "left",
        p: {
          marginTop: 0,
          ":last-child": {
            mb: 0,
          },
        },
        h1: {
          fontFamily: "pragmatica-extended",
          lineHeight: "2.5rem",
          fontSize: "2rem",
          mt: 0,
          fontWeight: "100",
        },
        h3: {
          lineHeight: "1.5rem",
          fontFamily: "pragmatica-extended",
          fontSize: "1rem",
          mt: 0,
          fontWeight: "600",
          ":last-of-type": {
            mb: 3,
          },
        },
        ...sx,
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default RichText
