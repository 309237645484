import React, { useEffect, useState } from "react"
import { Button, Flex, Box } from "@theme-ui/components"
import useDisableScroll from "../hooks/use-disable-scroll"
import { Link } from "gatsby"

const CookiePopup = ({prefix}) => {
  const [_, setScrollable] = useDisableScroll(false)
  const [shouldShow, setShouldShow] = useState(false)
  const [preferences, setPreferences] = useState({
    necessary: true,
  })

  const fields = ["Necessary", "Statistical", "Marketing", "Preferences"]

  const handleClick = (f) => {
    const normalized = f.toLowerCase()

    if (normalized === "necessary") {
      return
    }

    setPreferences((pref) => {
      const newPref = {
        ...pref,
        [normalized]: normalized in pref ? !pref[normalized] : true,
      }
      return newPref
    })
  }

  const handleAllowAll = (e) => {
    e.preventDefault()

    if (localStorage) {
      localStorage.setItem(
        "cc",
        JSON.stringify({
          necessary: true,
          statistical: true,
          marketing: true,
          preferences: true,
        })
      )
    }

    setScrollable(true)
    setShouldShow(false)
  }

  const handleCookieSubmit = (e) => {
    e.preventDefault()

    if (localStorage) {
      localStorage.setItem("cc", JSON.stringify(preferences))
    }

    setScrollable(true)
    setShouldShow(false)
  }

  const buttonStyles = {
    width: "150px",
    borderRadius: "0",
    backgroundColor: "#101820",
    paddingTop: "18px",
    paddingBottom: "18px",
  }

  useEffect(() => {
    if (localStorage) {
      const savedPrefs = localStorage.getItem("cc")
      if (!savedPrefs) {
        setShouldShow(true)
      } else {
        setScrollable(true)
      }
    } else {
      setScrollable(true)
    }
  }, [])

  if (!shouldShow) {
    return null
  }

  return (
    <Flex
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000000aa",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "9999",
      }}
    >
      <Flex
        onSubmit={handleCookieSubmit}
        as="form"
        px={["30px", "110px"]}
        py={["80px", "110px"]}
        sx={{
          flexDirection: ["column", "row"],
          backgroundColor: "white",
          maxWidth: "1000px",
          width: ["90%", "60%"],
        }}
      >
        <Flex
          mr={[0, 5]}
          pt={3}
          pr={[0, "100px"]}
          sx={{
            borderTop: "1px solid black",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              textTransform: "uppercase",
              fontSize: "20px",
              fontFamily: "pragmatica-extended",
              fontWeight: "200",
              marginBottom: "20px",
            }}
          >
            Cookie Consent
          </h2>
          <p
            style={{
              color: "#717A89",
              marginBottom: "50px",
            }}
          >
            At Blonde we are very attentive to the need for appropriate
            protection and responsible processing of all personal information
            that we receive and we give data security and confidentiality high
            priority. See our <Link to={`${prefix}privacy/`}>Privacy Policy</Link>
          </p>
          <Flex mb={[5, 0]}>
            {fields.map((f) => (
              <Flex
                mr={[3, 5]}
                sx={{
                  cursor: "pointer",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                key={f}
                onClick={() => handleClick(f)}
              >
                <input
                  name={f.toLowerCase()}
                  style={{ display: "none" }}
                  type="checkbox"
                  id={f.toLowerCase()}
                  checked={preferences[f.toLowerCase()] || false}
                />
                <Box
                  mb={2}
                  sx={{
                    backgroundColor: preferences[f.toLowerCase()]
                      ? "#717A89"
                      : "white",
                    width: "17px",
                    height: "17px",
                    border: "1px solid #717A89",
                  }}
                />
                <label
                  style={{
                    fontFamily: "pragmatica-extended",
                    fontWeight: "200",
                  }}
                  for={f.toLowerCase()}
                >
                  {f}
                </label>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Box sx={{ flexDirection: "column" }}>
          <Button
            onClick={handleAllowAll}
            mr={[3, 0]}
            mb={3}
            sx={{ ...buttonStyles }}
          >
            Allow all
          </Button>
          <Button
            type={"submit"}
            sx={{ ...buttonStyles, backgroundColor: "#717A89" }}
          >
            Save preferences
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}

export default CookiePopup
