exports.partitionCases = (cases) => {
  return cases.reduce((acc, next) => {
    if (next.departments) {
      for (const d of next.departments) {
        if (d in acc) {
          acc[d].push(next)
        } else {
          acc[d] = [next]
        }
      }
    } else {
      const key = next.department || "Video"
      if (key in acc) {
        acc[key].push(next)
      } else {
        acc[key] = [next]
      }
    }
    return acc
  }, {})
}
