import React, { useRef, useState, useEffect } from "react"
import { Flex, Box } from "@theme-ui/components"
import styled from "@emotion/styled"

import Caret from "../assets/caret.png"
import CaseLink from "./case-link"

const ScrollBoxContainer = styled(Flex)`
  position: absolute;
  top: 40px;
  bottom: 40px;
  width: 100%;

  .gradient {
    transition: background 500ms;
    display: flex;
    position: absolute;
    align-items: flex-end;
    pointer-events: none;
    padding-left: 10px;
    bottom: 0px;
    height: 100px;
    width: 100%;
    background: linear-gradient(
      #ffffff00,
      ${(props) => props.theme.colors.white}
    );
  }
`
const ScrollBox = styled(Flex)`
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  overflow: auto;
  margin-right: -50px;
  padding-right: 50px;
  &::-webkit-scrollbar {
    display: none;
  }
`

  const CaseNav = ({ cases, onMouseEnter, onMouseLeave, location, prefix }) => {
  const [prevVal, setPrevVal] = useState(0)
  const [showCaret, setShowCaret] = useState(false)
  const ref = useRef()

  useEffect(() => {
    if (ref) {
      if (ref.current.offsetHeight < ref.current.scrollHeight) {
        setShowCaret(true)
      }
    }

    const handleScroll = (event) => {
      const element = event.target

      setPrevVal(element.scrollTop)

      setShowCaret(
        element.scrollHeight - element.scrollTop !== element.clientHeight
      )
    }

    ref.current.addEventListener("scroll", handleScroll)
    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [ref])

  useEffect(() => {
    if (localStorage) {
      const val = localStorage.getItem(`s:${prefix}`)
      if (val) {
        ref.current.scrollTop = val
      }
    }
  }, [ref])

  const handleNav = () => {
    if (localStorage) {
      localStorage.setItem(`s:${prefix}`, prevVal)
    }
  }

  return (
    <ScrollBoxContainer>
      <ScrollBox ref={ref} sx={{ flex: "1", flexDirection: "column" }} mb={12}>
        {cases.map((c) => (
          <CaseLink
            key={c.slug}
            onClick={handleNav}
            onMouseEnter={() => onMouseEnter(c.contentful_id)}
            onMouseLeave={onMouseLeave}
            to={`${prefix}${c.slug}/`}
            location={location}
          >
            {c.clientName/* +" "+ c.title */ } {/* clientName */}
          </CaseLink>
        ))}
      </ScrollBox>
      {showCaret && (
        <Flex className="gradient">
          <Box
            sx={{
              position: "absolute",
              transform: "rotate(90deg)",
            }}
          >
            <img src={Caret} />
          </Box>
        </Flex>
      )}
    </ScrollBoxContainer>
  )
}

export default CaseNav
