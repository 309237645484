import React from "react"
import { Box, Flex, Grid } from "@theme-ui/components"
import RichText from "./rich-text"
import Caret from "../../assets/caret.png"

const Steps = ({ data, padBottom, padTop, padX }) => {
  const columnsTotal = data.columns ? data.columns.length : 0
  return (
    <Flex
      sx={{
        maxWidth: "960px",
        justifyContent: "center",
        width: "100%",
      }}
      mx={"auto"}
      px={padX != undefined ? padX : [3, "40px"]}
      pt={padTop ? [5, 6] : [1, 1]}
      pb={padBottom ? [5, 6] : [1, 1]}
    >
      <Flex sx={{ width: "100%", rowGap: ["4", "4", "inherit"] }}>
        {(data.columns || []).map(({ text, enlarge, center }, idx) => {
          return (
            <>
              <Flex
                key={idx}
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "300",
                    opacity: "0.75",
                  }}
                >
                  0{idx + 1}.
                </Box>
                <RichText
                  html={text.childMarkdownRemark.html}
                  enlarge={enlarge}
                  center={true}
                  baseFontSize={["9px", "10px", "11px"]}
                />
              </Flex>
              {idx != columnsTotal - 1 && (
                <Flex px={1} sx={{ alignItems: "center" }}>
                  <img style={{ margin: "0 auto", width: "6px" }} src={Caret} />
                </Flex>
              )}
            </>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default Steps
