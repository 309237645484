import React from "react"
import { Flex } from "@theme-ui/components"

export default ({ children }) => (
  <Flex
    sx={{
      scrollSnapType: "y mandatory",
      flexDirection: "column",
      position: "relative",
      width: "100%",
      minHeight: "100vh",
      margin: "0 auto",
    }}
  >
    {children}
  </Flex>
)
