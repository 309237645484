import { useEffect, useState } from "react"

const useDisableBackgroundScroll = (initialScrollable) => {
  const [scrollable, setScrollable] = useState(
    initialScrollable === undefined ? true : initialScrollable
  )

  useEffect(() => {
    const [htmlTag] = document.getElementsByTagName("html")
    const [bodyTag] = document.getElementsByTagName("body")

    if (scrollable) {
      htmlTag.style.removeProperty("overflow")
      bodyTag.style.removeProperty("overflow")
    } else {
      htmlTag.style.overflow = "hidden"
      bodyTag.style.overflow = "hidden"
    }
  }, [scrollable])

  return [scrollable, setScrollable]
}

export default useDisableBackgroundScroll
