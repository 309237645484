import React, { useState, useContext } from "react"
import { Flex, Box } from "@theme-ui/components"
import { Link } from "gatsby"
import { useResponsiveValue } from "@theme-ui/match-media"

import { InterfaceContext } from "../context/interface"
import Logo from "../assets/logo.svg"

import NavLink from "./nav-link"
import FeaturedCases from "./featured-case-nav"

export default ({ nav, dropup, subNav, isFeatured, featuredCases, location }) => {
  const { locale, translationLinks, isGridView, toggleGridView} = useContext(InterfaceContext)
  const [isExpanded, setExpanded] = useState(false)
  const isSmall = useResponsiveValue([true, false])

  const toggleExpand = () => {
    setExpanded((s) => !s)
  }

  const now = new Date()

  const [lang] = locale.split("-")
  let prefix = "/"
  if (lang !== "en") {
    prefix = `/${lang}/`
  }

  return (
    <Flex
      sx={{
        position: ["fixed", "sticky"],
        fontFamily: "heading",
        top: 0,
        bottom: ["unset", 0],
        transition: "background-color 500ms",
        backgroundColor: "white",
        width: ["100%", "600px"],
        zIndex: [1001, "unset"],
        justifyContent: "space-between",
        flexDirection: "column",
      }}
      role="navigation"
    >
      <Box
        px={4}
        sx={{
          height: "50px",
          display: ["flex", "none"],
          alignItems: "center",
          justifyContent: isExpanded ? "flex-end" : "space-between",
        }}
      >
        <Box
          onClick={toggleExpand}
          variant="navLink"
          sx={{
            color: "darkest",
            fontSize: isExpanded ? 4 : 2,
          }}
        >
          {isExpanded ? <>&times;</> : "Menu"}
        </Box>
      </Box>
      <Box
        pr={[4, 5]}
        pl={"90px"}
        sx={{
          height: "calc(100vh - 50px)",
          justifyContent: "space-between",
          flexDirection: "column",
          display: isExpanded ? "flex" : ["none", "flex"],
        }}
      >
        <Box sx={{ width: "95px" }} pb={5} pt={6}>
          <Link to={prefix}>
            <img src={Logo} />
          </Link>
        </Box>
        <Flex
          sx={{
            flexDirection: "column",
            flex: "1",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: ["none", "flex"] }}>
            <NavLink small to={prefix}>
              Home
            </NavLink>
            {isFeatured && (
              <NavLink onClick={toggleGridView} small ml={"28px"}>
                {isGridView ? "List View" : "Grid View"}
              </NavLink>
            )}
          </Box>
          {!isSmall && isFeatured && featuredCases ? (
            <FeaturedCases
              item={featuredCases}
              isFeatured={isFeatured}
              location={location}
              prefix={prefix}
            />
          ) : (
            nav?.items.map((i) => {
              if (i.internal?.type === "ContentfulFeaturedCases") {
                return (
                  <FeaturedCases
                    key={i.id}
                    item={i}
                    location={location}
                    prefix={prefix}
                  />
                  
                )
              }
              if (i.internal.type === 'ContentfulNavigationItem') {
                return (
                  <NavLink key={i.id} to={`${prefix}${i.link.slug}/`}>{i.title}</NavLink>
                )
              }

                
              if (
                isSmall ||
                (!isFeatured && i.internal.type === "ContentfulPage")
              ) {
                return (
                  <NavLink key={i.id} to={`${prefix}${i.slug}/`}>
                    {i.title}
                </NavLink>

                )
              }
              
              return null
            })
          )}
        </Flex>
        <Flex
          mt={3}
          mx={-3}
          px={3}
          sx={{
            flex: [1, "unset"],
            flexDirection: "column",
          }}
        >
          <Flex
            py="20px"
            sx={{
              justifyContent: ["center", "flex-start"],
              flexDirection: ["column", "row"],
              fontWeight: "400",
              fontSize: [2, 1],
              textTransform: "uppercase",
              a: {
                color: "darkest",
                marginBottom: [2, 0],
                marginRight: "30px",
                textDecoration: "none",
              },
              button: {
                color: "darkest",
              }
            }}
          >
            {/*
            <div className="dropup">
              <Link to={`${prefix}${dropup.items[0].slug}/`}>
                <button className="dropbtn">{dropup.items[0].title}</button>
              </Link>
              <div className="dropup-content">
                <Box sx={{backgroundColor: 'white', cursor: "pointer"}}>
                  {dropup.items.slice(1).map((i) => {
                    return <Link to={`${prefix}${i.slug}/`}>{i.title}</Link>
                  })}
                </Box>
              </div>
            </div>
            */}
              {subNav.items.map((i) => {
                return <Link to={`${prefix}${i.slug}/`}>{i.title}</Link>
              })}
          </Flex>
          <Flex
            sx={{
              flexDirection: ["column-reverse", "row"],
            }}
          >
            <Flex
              sx={{
                color: "darkest",
                flexDirection: "column",
                justifyContent: ["flex-end", "space-between"],
              }}
            >
              <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                Copyright © {now.getFullYear()}
              </Box>
              <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                All Rights Reserved
              </Box>
              <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                <Link to={`${prefix}tos`}>Terms Of Service</Link>
              </Box>
              <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                <Link to={`${prefix}privacy`}>Privacy Policy</Link>
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Flex
                px={[0, "20px"]}
                mb={[2, 0]}
                sx={{
                  color: "darkest",
                  fontSize: [1, 0],
                  textTransform: "uppercase",
                  maxWidth: "200px",
                  div: {
                    marginLeft: "3px",
                    marginRight: "3px",
                    textDecoration: "none",
                  },
                }}
              >
                
                <Box
                  sx={{fontWeight: locale == 'en-US' ? 'bold' : 'normal', marginLeft: "0 !important", a: { textDecoration: "none" } }}
                >
                  <Link
                    textDecoration="none"
                    to={`${translationLinks["en-US"]}`}
                  >
                    EN
                  </Link>
                </Box>
                /
                <Box
                  sx={{ fontWeight: locale == 'da-DK' ? 'bold' : 'normal', a: { textDecoration: "none" } }}
                  fontWeight={locale === "da-DK" && "bold"}
                >
                  <Link
                    textDecoration="none"
                    to={`/da${translationLinks["da-DK"]}`}
                  >
                    DA
                  </Link>
                </Box>
                /
                {
                <Box
                  sx={{fontWeight: locale == 'sv-SE' ? 'bold' : 'normal', a: { textDecoration: "none" } }}
                >
                  <Link
                    textDecoration="none"
                    to={`/sv${translationLinks["sv-SE"]}`}
                  >
                    SV
                  </Link>
                </Box>}
              </Flex>
              <Flex
                px={[0, "20px"]}
                mb={[2, 0]}
                sx={{
                  color: "darkest",
                  fontSize: [1, 0],
                  textTransform: "uppercase",
                  maxWidth: "200px",
                  a: {
                    ":not(:first-of-type)": {
                      marginLeft: "3px",
                    },
                    marginRight: "3px",
                    textDecoration: "none",
                  },
                }}
              >
                <a target="_blank" href="https://instagram.com/blonde___inc">
                  Instagram
                </a>
                /
                <a target="_blank" href="https://vimeo.com/blondeinc">
                  Vimeo
                </a>
                /
                <a
                  target="_blank"
                  href="https://linkedin.com/company/blondeinc"
                >
                  LinkedIn
                </a>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}
