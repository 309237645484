import React, { useEffect, useState, useContext } from "react"
import { Flex } from "@theme-ui/components"
import { useResponsiveValue } from "@theme-ui/match-media"
import { kebabCase } from "lodash"

import { InterfaceContext } from "../context/interface"
import { partitionCases } from "../utils/partition-cases"
import { getCaseKey } from "../utils/get-case-key"

import NavLink from "./nav-link"
import CaseNav from "./case-nav"

const FeaturedCases = ({ item, isFeatured, location, prefix }) => {
  const { setHoverCase } = useContext(InterfaceContext)

  const isActive = isFeatured
  const partitioned = partitionCases(item.cases)
  const additionalRaw = Object.entries(partitioned).reduce((acc, [key]) => {
    if (key !== "Video") {
      acc.push(key)
    }
    return acc
  }, [])

  const additional = additionalRaw.sort((a, b) => a.localeCompare(b))
  const keys = Object.entries(partitioned).map(([next]) => next)
  const caseKey = getCaseKey(location.pathname, item.slug, keys) || "Video"
  const cases = partitioned[caseKey]

  const handleMouseEnter = (contentfulId) => {
    setHoverCase(contentfulId)
  }

  const handleMouseLeave = () => {
    // setHoverCase()
  }

  return (
    <Flex
      sx={{
        position: "relative",
        flexDirection: "column",
        flex: isActive && "1",
      }}
    >
      <Flex>
        <NavLink
          light={isActive}
          active={isActive && caseKey === "Video"}
          to={`${prefix}${item.slug}/`}
        >
          {isActive ? "Film" : item.name}
        </NavLink>
        <NavLink
          light={isActive}
          active={isActive && caseKey === "Design"}
          to={`${prefix}${item.slug}/design/`}
          ml={"40px"}
        >
          {isActive && "Design"}
        </NavLink>
        <NavLink
          light={isActive}
          active={isActive && caseKey === "Stills"}
          to={`${prefix}${item.slug}/stills/`}
          ml={"40px"}
        >
          {isActive && "Stills"}
        </NavLink>
      </Flex>
      {isActive && (
        <CaseNav
          prefix={`${prefix}${item.slug}/${kebabCase(caseKey)}/`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          location={location}
          path={item.slug}
          cases={cases}
        />
      )}
    </Flex>
  )
}

export default FeaturedCases
