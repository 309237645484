import React from "react"
import {Link} from "gatsby"
import { Box } from "@theme-ui/components"

const NavLink = ({ light, onClick, to, small, active, children, ...rest }) => (
  <Box
    onClick={!to ? onClick : undefined}
    mb={small ? "10px" : "15px"}
    variant={"navLink"}
    sx={{
      color: "darkest",
      fontWeight: active ? "heading" : small || light ? "light" : "body",
      lineHeight: small ? "10px" : "heading",
      fontSize: small ? 1 : 3,
    }}
    {...rest}
  >
    {to ? (
      <Link onClick={onClick} to={to}>
        {children}
      </Link>
    ) : (
      children
    )}
  </Box>
)

export default NavLink
