import React, { useState, useRef, useEffect, useContext } from "react"
import { Flex, Box } from "@theme-ui/components"
import { useResponsiveValue } from "@theme-ui/match-media"

import { InterfaceContext } from "../context/interface"
import Container from "./container"
import Navigation from "./navigation"
import Logo from "../assets/logo.svg"
import CookiePopup from "./cookie-popup"

import CaretDown from "../assets/caret-down.svg"

import "./base.css"
import Popup from "./popup/popup"

export default ({
  nav,
  dropup,
  subNav,
  prefix,
  shouldShowNav,
  isFeatured,
  featuredCases,
  children,
  locale,
  location,
  renderAboveFold,
}) => {
  const [lastScroll, setLastScroll] = useState(0)
  const [scrolled, setScrolled] = useState(false)
  const mainRef = useRef()
  const { setLocale } = useContext(InterfaceContext)

  const isSmall = useResponsiveValue([true, false])
  useEffect(() => {
    if (isSmall) {
      if (localStorage) {
        localStorage.removeItem("bf")
      }
      return
    }

    if (mainRef && renderAboveFold) {
      if (localStorage) {
        const scroll = localStorage.getItem("bf")
        if (scroll) {
          mainRef.current.scrollIntoView()
          return
        }
      }

      const handler = (e) => {
        if (scrolled) {
          return
        }

        if (lastScroll < 100) {
          if (lastScroll < window.scrollY) {
            if (mainRef.current) {
              mainRef.current.scrollIntoView({ behavior: "smooth" })
              setScrolled(true)
              if (localStorage) {
                localStorage.setItem("bf", "true")
              }
            }
          }
        }

        setLastScroll(window.scrollY)
      }
      window.addEventListener("scroll", handler)

      return () => {
        window.removeEventListener("scroll", handler)
      }
    }
  }, [mainRef, renderAboveFold, isSmall])

  useEffect(() => {
    setLocale(locale)
  }, [])
  return (
    <Container>
      <CookiePopup prefix={prefix} />
      <Popup />
      {renderAboveFold && (
        <Box
          px={[4, 0]}
          pr={4}
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#1A1F25",
            height: "100vh",
          }}
        >
          <Flex
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              width: ["0", "600px"],
              zIndex: [1001, "unset"],
            }}
          />
          <Flex
            sx={{
              width: "100%",
              flexDirection: ["column", "row"],
              alignItems: "end",
            }}
          >
            <Box sx={{ width: "95px" }} pb={5} pt={[0, 6]} mr={5}>
              <img src={Logo} />
            </Box>
            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                flex: 1,
              }}
            >
              {renderAboveFold()}
              <Flex
                mt={4}
                sx={{
                  alignItems: "center",
                  fontSize: 2,
                  fontWeight: 300,
                  fontFamily: "pragmatica-extended",
                  color: "#717A89",
                }}
                pb={5}
              >
                SCROLL DOWN
                <Box sx={{ height: 8 }} ml={1}>
                  <img height="100%" src={CaretDown} />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
      <Flex ref={mainRef}>
        {shouldShowNav && (
          <Navigation
            nav={nav}
            dropup={dropup}
            subNav={subNav}
            featuredCases={featuredCases}
            isFeatured={isFeatured}
            location={location}
          />
        )}
        <Flex
          sx={{
            width: "100%",
            justifyContent: "flex-start",
            flexDirection: "column",
            paddingTop: [shouldShowNav ? "50px" : "0", "0"],
            backgroundColor: "background",
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Container>
  )
}
