import React from "react"
import { Box } from "@theme-ui/components"
import {Link} from 'gatsby'

const CaseLink = ({
  to,
  onClick,
  onMouseLeave,
  onMouseEnter,
  location,
  children,
}) => (
  <Box
    onClick={onClick}
    onMouseLeave={() => {
      const [, ...slug] = to
      onMouseLeave(slug)
    }}
    variant={"navLink"}
    mb={2}
    sx={{
      cursor: "default",
      fontSize: "10px",
      lineHeight: "body",
      fontWeight: "light",
      a: {
        color: location.hash.includes(to) ? "darkest" : "gray",
        ":hover": {
          color: "darkest",
        },
      },
    }}
  >
    <Link
      to={`${to}`}
      onMouseEnter={() => {
        const [, ...slug] = to
        onMouseEnter(slug)
      }}
    >
      {children}
    </Link>
  </Box>
)

export default CaseLink
