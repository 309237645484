import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box } from "theme-ui"
import Cross from "../../assets/icon-cross.svg"
import FormModule from "../form/form-wrapper"

const userHasDismissedPopup = () => {
  if (window && window.localStorage.getItem("popup-dismissed") == "yes") {
    return true
  } else {
    return false
  }
}

const storeDismissal = () => {
  if (window) {
    window.localStorage.setItem("popup-dismissed", "yes")
  }
}

const Popup = () => {
  const [show, setShow] = useState(false)
  const [dismissed, setDismissed] = useState(false)

  const { popup } = useStaticQuery(graphql`
    query PopupQuery {
      popup: contentfulForm(title: { eq: "Popup Form" }) {
        ...formFragment
      }
    }
  `)

  useEffect(() => {
    setTimeout(() => {
      if (!userHasDismissedPopup()) {
        setShow(true)
      }
    }, 500)
  }, [])

  useEffect(() => {
    if (dismissed) {
      storeDismissal()
      setShow(false)
    }
  }, [dismissed])

  if (!popup) {
    return <></>
  }

  return (
    <Box
      py={"3rem"}
      px={"3rem"}
      sx={{
        transition: "all 0.3s ease-out",
        opacity: show ? "1" : "0",
        pointerEvents: show ? "all" : "none",
        position: "fixed",
        bottom: 3,
        right: 3,
        left: [3, "auto", "auto"],
        background: popup.backgroundColor ? popup.backgroundColor : "#D9D9D9",
        color: popup.textColor ? popup.textColor : "#4B4B4B",
        zIndex: "500",
        borderRadius: 10,
        maxWidth: "400px",
      }}
    >
      <Box
        onClick={() => setDismissed(true)}
        my={4}
        mx={4}
        sx={{
          cursor: "pointer",
          position: "absolute",
          top: 0,
          right: 0,
          width: "15px",
        }}
      >
        <img src={Cross} alt="exit icon" />
      </Box>
      <Box mt={3}>
        <FormModule
          data={popup}
          key={popup.id}
          px={0}
          itemsWidth={["100%"]}
          color={popup.textColor}
        />
      </Box>
    </Box>
  )
}

export default Popup
