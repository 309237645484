import React from "react"
import { Box, Flex } from "theme-ui"
import RichText from "../text/rich-text"
import ContentModule from "../content-modules/content-module"
import FormModule from "./form-module"

const FormWrapper = (props) => {
  const { data, px, itemsWidth, padDescription, color } = props

  const colWidth =
    itemsWidth != undefined ? itemsWidth : ["100%", "100%", "50%"]

  return (
    <Flex
      px={px != undefined ? px : [3, "40px"]}
      mx={"auto"}
      sx={{ maxWidth: "1200px", flexWrap: "wrap", rowGap: 4 }}
    >
      {data.description.childMarkdownRemark.html && (
        <Box sx={{ width: colWidth }} pr={padDescription ? [0, 0, 5] : 0}>
          <RichText html={data.description.childMarkdownRemark.html} />

          {data.contentModules?.length > 0 && (
            <Box my={2}>
              {data.contentModules.map((module) => (
                <ContentModule module={module} padX={0} />
              ))}
            </Box>
          )}
        </Box>
      )}
      <Box sx={{ width: colWidth }}>
        <Box sx={{ maxWidth: "560px" }}>
          <FormModule formData={data} color={color} />
        </Box>
      </Box>
    </Flex>
  )
}

export default FormWrapper
