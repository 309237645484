const { kebabCase } = require("lodash")

exports.getCaseKey = (pathname, active, keys) => {
  const splats = pathname.split("/")
  const indexOf = splats.indexOf(active)
  if (indexOf >= 0) {
    return keys.find((key) => kebabCase(key) === splats[indexOf + 1])
  }
  return null
}
